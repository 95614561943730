import styles from "../HomePage/HomePage.module.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bg1 from "../../assets/Images/bg1.jpg";
import { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import playstore from "../../assets/Images/playstore.png";
import appstore from "../../assets/Images/appstore.png";
import TrainerData from "./TrainerData";
import { AiFillStar } from "react-icons/ai";
import appIcon from "../../assets/Images/ratinglogo.png";
import HomeSliderImg from "./HomeSliderImg";
import SublyApi from "../../helpers/Api";
import "../../assets/Styles/Common.css";
import { useDispatch, useSelector } from "react-redux";
import { setBgImages } from "../../store/slices/UserSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import { HOME_IMAGE } from "../../utils/Constants";
import $ from "jquery";

// -------------function for home page-----------
function HomePage() {
  const { t } = useTranslation();
  const [backgroundImage, setBackgroundImage] = useState();
  const [packageList, setPackageList] = useState("");
  const [bgIndex, setBgIndex] = useState(0);
  const dispatch = useDispatch();
  const { backgroundImageRes } = useSelector((state) => state.user);

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  //---------function for get home image slider api calling----------
  useEffect(() => {
    async function imageSlider() {
      dispatch(setBgImages(HOME_IMAGE.IMAGE_TYPE)).then((responsejson) => {
        const response = responsejson.payload;
        if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
          setBackgroundImage(response.settings.data[0].webImage);
        }
      });
    }
    imageSlider();
  }, []);

  //---------function for home background image change---------
  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImage((previousImage) => {
        if (backgroundImageRes.length > 0) {
          let lastImgIndex = backgroundImageRes.length - 1;
          if (lastImgIndex == bgIndex) {
            setBgIndex(0);
            const webImage = backgroundImageRes[0].webImage;
            return webImage;
          } else {
            const newBgIndex = bgIndex + 1;
            setBgIndex(newBgIndex);
            const webImage = backgroundImageRes[newBgIndex].webImage;
            return webImage;
          }
        } else {
          setBackgroundImage(bg1);
        }
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [bgIndex]);

  // --------------------function for home page slider img api--------------
  useEffect(() => {
    async function subscriptionPackageList() {
      const details = await SublyApi.getPackageList();
      details && setPackageList(details.data);
    }
    subscriptionPackageList();
  }, []);

  const bgDefaultCss = {
    backgroundSize: "cover",
    width: "100%",
    height: "100vh",
    backgroundPositionX: "center",
    backgroundColor: "transparent",
    transition: "background 0.3s ease",
    // backgroundPosition:"center",
  };
  const bgCss = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    width: "100%",
    height: "100vh",
    backgroundPositionX: "center",
    backgroundColor: "transparent",
    transition: "background 0.3s ease",
    // backgroundPosition:"center",
  };

  return (
    <>
      <Header />
      <div className="main">
        <div
          className={styles.headerSection}
          style={backgroundImage ? bgCss : bgDefaultCss}
        >
          <div className={styles.headingText}>
            <h2>{t("HEADING")}</h2>
            <p>{t("PARA")}</p>

            <div className={styles.loginBtn}>
              <div className={styles.StartUp}>
                <NavLink to="/login">{t("LOGIN")}</NavLink>
              </div>
              <div className={styles.StartUp1}>
                <NavLink to="/sign-up">{t("SIGN_UP")}</NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="bgSection">
          <div className={styles.textSection}>
            <Container>
              <div className={styles.middileSection}>
                <h2 className={styles.headSection}>{t("HEADING1")}</h2>
                <p>{t("LONG_PARA")}</p>
              </div>
              <div className={styles.borderBottom}>
                <span></span>
              </div>
            </Container>
          </div>

          <div className={styles.trainerSection}>
            <Container className="sliderContent">
              <div className={styles.appHeader}>
                <h2 className={styles.headSection}>{t("HEADING2")}</h2>
              </div>
              {packageList &&
                packageList.get_success_story &&
                packageList.get_success_story.length > 0 &&
                <HomeSliderImg packageList={packageList} />}
              <div className={styles.borderBottom}>
                <span></span>
              </div>
            </Container>

            <Container>
              <div className={styles.appStoreRating}>
                <div className={styles.reviewSection}>
                  <div className={styles.AppleftImg}>
                    <img src={appIcon} alt="dovies-app" />
                  </div>
                  <div className={styles.ratingHeading}>
                    <h2>{t("PROJECT_HEADING")}</h2>
                    <div className={styles.starIcon}>
                      <p>
                        {t("HOME_RATING")}
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <span>{t("HOME_REWIEV")}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.socialSection}>
                  <a
                    href={process.env.REACT_APP_APP_STORE_LINKS}
                    target="blank"
                  >
                    <img src={appstore} alt="app-store" />
                  </a>
                  <a
                    href={process.env.REACT_APP_PLAY_STORE_LINKS}
                    target="blank"
                  >
                    <img src={playstore} alt="play-store" />
                  </a>
                </div>
              </div>
            </Container>

            <Container>
              <div className={styles.bottompara}>
                <p>{t("BOTTOM_PARA")}</p>
                <div className={styles.signupfree}>
                  <NavLink to="/sign-up" className={styles.btnBanner1}>
                    {t("SIGN_UP_BTN_FREE")}
                  </NavLink>
                </div>
                <div className={styles.borderBottom}>
                  <span></span>
                </div>
              </div>
            </Container>

            <Container>
              <Row>
                {TrainerData.TrainingImg.map((item, index) => (
                  <Col lg={12} key={index}>
                    <div className={styles.trainingImg} >
                      <img src={item.image} alt="trainer-image" />
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
export default HomePage;
