import facebook from "../../assets/Images/fb.png";
import instagram from "../../assets/Images/insta.png";
import twitter from "../../assets/Images/twitter.png";
import icon from "../../assets/Images/dovies_ico.svg";

const SocialLinks = [
    {
        id: "1",
        link: process.env.REACT_APP_FACEBOOK_LINKS,
        image: `${facebook}`,
    },
    {
        id: "2",
        link: process.env.REACT_APP_INSTRAGRAM_LINKS,
        image: `${instagram}`,
    },
    {
        id: "3",
        link: process.env.REACT_APP_TWITTER_LINKS,
        image: `${twitter}`,
    },
  
];
export default SocialLinks;

