import { Container } from "react-bootstrap";
import styles from "../Content/Content.module.css";
import SublyApi from "../../helpers/Api";
import { useEffect, useState } from "react";
import { CONTENT } from "../../utils/Constants";
import DOMPurify from 'dompurify';
import { useTranslation } from "react-i18next";

//----------function for privacy policy content----------- 
function PrivacyPolicy() {
    const [contentList, setContentList] = useState("");
    const { t } = useTranslation();

    //---------function for get privacy policy content api calling---------- 
    useEffect(() => {
        async function privacyContent() {
            const details = await SublyApi.getContent(CONTENT.PRIVACY_POLICY);
            details && setContentList(details.data.page_content);
        }
        privacyContent();
    }, []);

    return (
        <>
            {contentList &&
                <div className="main">
                    <div className={styles.contentText}>
                        <Container>
                            <h2>{t("PRIVACY_POLICY")}</h2>
                            <div
                            className="textSection"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(contentList)
                                }}>
                            </div>
                        </Container>
                    </div>
                </div>
            }
        </>
    );
}
export default PrivacyPolicy;




