import { Container, Form } from "react-bootstrap";
import styles from "./SignUp.module.css";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/Images/ratinglogo.png";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { clearForm, userLogin } from "../../store/slices/UserSlice";
import { Toast } from "../../utils/Toaster";
// import FacebookSocialLogin from "../../commonComponents/SocialLogin/FacebookSocialLogin";
import GoogleSocial from "../../commonComponents/SocialLogin/GoogleSocial";
import { useEffect } from "react";
import Loader from "../../utils/Loader/Loader";
import $ from "jquery";
import { STATUS_CODES } from "../../utils/StatusCode";

// --------function for login form--------
function Login() {
  const { isLoading } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(clearForm(true));
  }, [dispatch]);

  // --------function for submit login form--------
  const onSubmit = async (formdata) => {
    const data = { password: formdata.password, userName: formdata.userName };
    dispatch(userLogin(data)).then((responsejson) => {
      const response = responsejson.payload;
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        Toast.fire({
          icon: "success",
          title: response.settings.message,
        });

        if (location && location.state) {
          if (location.state.packageName == true) {
            navigate("/subscription/detail", {
              state: {
                packageName: true,
                packageDetail: location.state.packageDetail,
              },
            });
          } else if (location.state.planSection == true) {
            navigate("/exclusive/paypal", {
              state: {
                planSection: true,
                planDetails: location.state.planDetails,
              },
            });
          } else {
            navigate("/profile");
          }
        } else {
          navigate("/profile");
        }
      } else {
        Toast.fire({
          icon: "error",
          title: response && response.settings.message,
        });
      }
    });
  };

  return (
    <>
      {isLoading === true ? <Loader /> : ""}
      <div className="main">
        <section className={`${styles.SignUpWrapper} SignUpPage`}>
          <Container>
            <div className={styles.FormBlock}>
              <div className={styles.FormSection}>
                <div className={styles.SignupTitle}>
                  <NavLink to="/">
                    <img src={Logo} alt="logo" />
                  </NavLink>
                </div>
                <GoogleSocial />
                <div className={styles.loginLine}>
                  <span className={styles.LineContent}>Or</span>
                </div>
                <div className={styles.loginPara}>
                  <p>{t("SIGN_IN")}</p>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <Form.Group className={styles.FormGroup}>
                      <Form.Control
                        className={styles.FormInput}
                        placeholder={t("PROFILE_USERNAME")}
                        type="text"
                        {...register("userName", {
                          required: {
                            value: true,
                            message: `${t("USERNAME_REQUIRED")}`,
                          },
                        })}
                      />

                      {errors.userName && (
                        <span className="errorMsg">
                          {errors.userName.message}
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group className={styles.FormGroup}>
                      <Form.Control
                        className={styles.FormInput}
                        placeholder={t("PASSWORD")}
                        type="password"
                        {...register("password", {
                          required: {
                            value: true,
                            message: `${t("PASSWORD_REQUIRED")}`,
                          },
                        })}
                      />

                      {errors.password && (
                        <span className="errorMsg">
                          {errors.password.message}
                        </span>
                      )}
                    </Form.Group>
                    <div className={styles.loginTerms}>
                      <p>
                        {t("LOGIN_TERMS")}{" "}
                        <NavLink to="/privacy-policy">
                          {" "}
                          {t("PRIVACY_POLICY")}{" "}
                        </NavLink>{" "}
                        {t("AND_OR")}{" "}
                        <NavLink to="/terms-condition">
                          {t("TERMS_OF_USE")}
                        </NavLink>
                      </p>
                    </div>
                  </div>
                  <div className={styles.loginBtn}>
                    <button>{t("SIGN_IN_BUTTON")}</button>
                  </div>
                </Form>

                <div className={styles.loginAccountSection}>
                  <p className={styles.joinText}>
                    {t("NOT_A_MEMBER")}{" "}
                    <NavLink to="/sign-up">{t("JOIN_US")}</NavLink>
                  </p>
                  <div className={styles.Terms}>
                    <p>
                      <NavLink to="/forgot-password">
                        {t("FORGOT_PASSWORD")}
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
}

export default Login;
