import SignUp from "./SignUpPage";
import About from "./About";
import CreateUser from "./CreateUser";
import SignupDone from "./SignupDone";
import { useState } from "react";

function SignupFlow(){
    const [switchTo, setSwitchTo] = useState(0);
    const [previousValue, setPreviousValue] = useState(false);
    
    return(<>
    {switchTo == 0 ? 
    <SignUp setSwitchTo={setSwitchTo} previousValue={previousValue} setPreviousValue={setPreviousValue}/>: switchTo == 1 ? 
    <About setSwitchTo={setSwitchTo} previousValue={previousValue} setPreviousValue={setPreviousValue}/>: switchTo==2 ?
    <CreateUser setSwitchTo={setSwitchTo} setPreviousValue={setPreviousValue}/> : switchTo==3 ?
    <SignupDone setSwitchTo={setSwitchTo}/> : switchTo==4 }
    </>)
}

export default SignupFlow;