export const DEVICE_TYPE = {
  DEVICE_TYPE: "web",
};

export const SOCIAL_NETWORK_TYPE = {
  SOCIAL_NETWORK_FB: "FB",
  SOCIAL_NETWORK_GOOGLE: "GOOGLE",
};

export const DEVICE_WEBSITE = {
  DEVICE_TYPE1: "Website",
};

export const CONTENT = {
  ABOUT_US: "aboutapplication",
  PRIVACY_POLICY: "privacypolicy",
  TERMS_CONDITION: "termsconditions",
};

export const HOME_IMAGE = {
  IMAGE_TYPE: "WEB",
};

export const PLAN = {
  WORKOUT_PLAN: 1,
  STREAM_WORKOUT_PLAN: 2,
};

export const PAGE = {
  PAGEONE: 1,
}

export const TRANSACTION_TYPE = {
  SALE: "Sale",
  SUBSCRIPTION: "Subscription",
}

export const ACCESS_LEVEL = {
  LOCK: "LOCK",
  OPEN: "OPEN",
}

export const SESION_TRANSACTION_TYPE = {
  SALE: 0,
  Subscription: 1,
}

