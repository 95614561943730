import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import styles from "./SignUp.module.css";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/Images/ratinglogo.png";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  checkEmailAvalibity,
  setSignupData1,
  clearForm,
} from "../../store/slices/UserSlice";
// import FacebookSocialLogin from "../../commonComponents/SocialLogin/FacebookSocialLogin";
import GoogleSocial from "../../commonComponents/SocialLogin/GoogleSocial";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import $ from "jquery";
import { STATUS_CODES } from "../../utils/StatusCode";
import { subYears } from 'date-fns';


function SignUp({ setSwitchTo, previousValue }) {
  const { signupData1 } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selected, setSelected] = useState("");
  const [error, setError] = useState("");
  const [detail, setSocialDetail] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [formatedDate, setFormatedDate] = useState("");
  const [dateError, setDateError] = useState("");

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  // const currentYear = new Date().getFullYear();
  // const startYear = currentYear - 200; // Adjust the number of years to display

  const currentDate = new Date();
  const minDate = subYears(currentDate, 100);
  const maxDate = currentDate;

  const handleDateChange = (date) => {
    const birthDate = new Date(date);
    const year = birthDate.getFullYear();
    const month = birthDate.getMonth() + 1;
    const day = birthDate.getDate();
    const newDate = year + "-" + month + "-" + day;
    setFormatedDate(newDate);
    const dates = new Date(signupData1.dob);
    setSelectedDate(dates);
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (previousValue == false) {
      dispatch(clearForm(true));
    } else {
      setValue("fullName", signupData1.fullName);
      setValue("email", signupData1.email);
      const convertDate = moment(signupData1.dob).format("YYYY, MM, DD");
      const dob = new Date(convertDate);
      if (signupData1.dob == undefined) {
        setSelectedDate("");
      } else {
        setSelectedDate(dob);
      }
      setSelected(signupData1.country_id);
      setValue("password", signupData1.password);
      setValue("confirmPassword", signupData1.confirmPassword);
    }
  }, [dispatch]);

  useEffect(() => {
    if (location.state) {
      setSocialDetail(location.state);
    }
    if (detail) {
      setValue("fullName", detail.name ? detail.name : "");
      setValue("email", detail.email ? detail.email : "");
    }
  }, [detail]);

  function handleErorchange() {
    setError("");
  }

  const onSubmit = async (formdata) => {
    const data = {
      ...formdata,
      country_id: selected,
      dob: formatedDate ? formatedDate : signupData1.dob,
    };
    if (error == "") {
      let requestData = new FormData();
      requestData.append("email", formdata.email.trim());
      dispatch(checkEmailAvalibity(requestData)).then((responsejson) => {
        const response = responsejson.payload;
        if (response && response.data.is_available == 0) {
          setError(t("EXISTS_EMAIL"));
        }
        if (response && response.data.is_available == STATUS_CODES.SUCCESS_API) {
          let userData = { ...data, socialUser: detail };
          dispatch(setSignupData1(userData));
          setError("");
          setSwitchTo(1);
        }
      });
    }
  };

  const passwordKeyPress = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  const onInputChange = (e) => {
    setError("");
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  return (
    <>
      {/* <HeaderSection /> */}
      <div className="main">
        <section className={`${styles.SignUpWrapper} SignUpPage`}>
          <Container>
            <div className={styles.FormBlock}>
              <div className={styles.FormSection}>
                <div className={styles.SignupTitle}>
                  <NavLink to="/">
                    <img src={Logo} alt="logo" />
                  </NavLink>
                  <h2>
                    {t("CREATE_YOUR")} <br /> {t("DOVIESFITNESS")} <br />
                    {t("ACCOUNT_TEXT")}
                  </h2>
                </div>

                {detail && detail.isSocialLogin == true ? (
                  ""
                ) : (<GoogleSocial
                  loadFrom="signup"
                  setSocialDetail={setSocialDetail}
                />)}

                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  {" "}
                  <div className={styles.Line}></div>
                  <div>
                    <div className="capitalLetter">
                      <Form.Group className={styles.FormGroup}>
                        <Form.Control
                          className={styles.FormInput}
                          placeholder={t("FULLNAME")}
                          {...register("fullName", {
                            onChange: (e) => {
                              handleKeyPress(e);
                            },
                            required: {
                              value: true,
                              message: `${t("FULLNAME_REQUIRED")}`,
                            },
                            maxLength: {
                              value: 30,
                              message: `${t("NAME_MAXLENGTH")}`,
                            },
                          })}
                        />

                        {errors.fullName && (
                          <span className="errorMsg">
                            {errors.fullName.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                    <Form.Group className={styles.FormGroup}>
                      {detail && detail.email ? (
                        <Form.Control
                          className={styles.FormInput}
                          disabled
                          placeholder={t("EMAIL")}
                          autoComplete="off"
                          {...register("email", {
                            onChange: (e) => onInputChange(e),
                            required: {
                              value: true,
                              message: `${t("EMAIL_REQUIRED")}`,
                            },
                            pattern: {
                              value:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: `${t("INVALID_EMAIL")}`,
                            },
                          })}
                        />
                      ) : (
                        <Form.Control
                          className={styles.FormInput}
                          placeholder={t("EMAIL")}
                          autoComplete="off"
                          {...register("email", {
                            onChange: (e) => onInputChange(e),
                            required: {
                              value: true,
                              message: `${t("EMAIL_REQUIRED")}`,
                            },
                            pattern: {
                              value:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: `${t("INVALID_EMAIL")}`,
                            },
                          })}
                        />
                      )}

                      {error && <span className="errorMsg">{error}</span>}
                      {errors.email && (
                        <span className="errorMsg">{errors.email.message}</span>
                      )}
                    </Form.Group>
                    <Form.Group className={styles.FormGroup}>
                      <div className="datePicker">
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => {
                            handleDateChange(date);
                            setSelectedDate(date);
                          }}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Date of birth (Optional)"
                          showYearDropdown
                          showMonthDropdown
                          changeMonth
                          scrollableYearDropdown
                          yearDropdownItemNumber={100}
                          // maxDate={new Date()}
                          minDate={minDate}
                          maxDate={maxDate}
                        />
                      </div>
                      {dateError && <span className="errorMsg">{dateError}</span>}
                    </Form.Group>
                    <Form.Group className={styles.FormGroup}>
                      <ReactFlagsSelect
                        className="flagSelect"
                        searchable={true}
                        searchPlaceholder="Search countries"
                        placeholder="Country (Optional)"
                        selected={selected}
                        onSelect={(code) => setSelected(code)}
                      />
                    </Form.Group>
                    {detail && detail.isSocialLogin == true ? (
                      ""
                    ) : (
                      <>
                        <Form.Group className={styles.FormGroup}>
                          <Form.Control
                            className={styles.FormInput}
                            placeholder={t("PASSWORD")}
                            type="password"
                            autoComplete="new-password"
                            {...register("password", {
                              onChange: (e) => {
                                passwordKeyPress(e);
                              },
                              required: {
                                value: true,
                                message: `${t("PASSWORD_REQUIRED")}`,
                              },
                              minLength: {
                                value: 5,
                                message: `${t("PASS_MINLENGTH")}`,
                              },
                              maxLength: {
                                value: 30,
                                message: `${t("PASS_MAXLENGTH")}`,
                              },
                            })}
                          />

                          {errors.password && (
                            <span className="errorMsg">
                              {errors.password.message}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group className={styles.FormGroup}>
                          <Form.Control
                            className={styles.FormInput}
                            placeholder={t("CONFIRM_PASSWORD")}
                            type="password"
                            autoComplete="new-password"
                            {...register("confirmPassword", {
                              onChange: (e) => {
                                passwordKeyPress(e);
                              },
                              required: {
                                value: true,
                                message: `${t("CONFIRM_PASSWORD_REQUIRED")}`,
                              },
                              minLength: {
                                value: 5,
                                message: `${t("PASS_MINLENGTH")}`,
                              },
                              maxLength: {
                                value: 30,
                                message: `${t("PASS_MAXLENGTH")}`,
                              },
                              validate: (value) =>
                                value === watch("password") ||
                                `${t("MATCH_PASS")}`,
                            })}
                          />

                          {errors.confirmPassword && (
                            <span className="errorMsg">
                              {errors.confirmPassword.message}
                            </span>
                          )}
                        </Form.Group>
                      </>
                    )}
                    <div className={styles.Terms}>
                      <p>
                        {t("SIGNUP_TERMS")}{" "}
                        <NavLink to="/privacy-policy">
                          {" "}
                          {t("PRIVACY_POLICY")}{" "}
                        </NavLink>{" "}
                        {t("AND_OR")}{" "}
                        <NavLink to="/terms-condition">
                          {t("TERMS_OF_USE")}
                        </NavLink>
                      </p>
                    </div>
                    <div className={styles.signUpBtn}>
                      <button type="submit" onClick={() => handleSubmit}>
                        {t("CREATE_ACCOUNT")}
                      </button>
                    </div>
                  </div>
                  <div
                    className={`${styles.FormSection} ${styles.AccountSection}`}
                  >
                    <p>
                      {t("HAVE_AN_ACCOUNT")}{" "}
                      <NavLink to="/login">{t("LOG_IN")}</NavLink>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
}

export default SignUp;
