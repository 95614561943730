import trainer1 from "../../assets/Images/Mask2.png";
import trainer2 from "../../assets/Images/Mask.png";
import trainer3 from "../../assets/Images/Mask1.png";
// import img1 from "../../assets/Images/mobileimg.png";
import img1 from "../../assets/Images/newHome.png";

import { t } from "i18next";

const TrainerDetail = [
    {
        id: "1",
        text: `${t("TRAINER_NAME1")} `,
        image: `${trainer1}`,
    },
    {
        id: "2",
        text: `${t("TRAINER_NAME2")} `,
        image: `${trainer2}`,
    },
    {
        id: "3",
        text: `${t("TRAINER_NAME3")} `,
        image: `${trainer3}`,
    },
];

const TrainingImg = [
    {
        id: "1",
        image: `${img1}`,
    },
   
];

export default { TrainerDetail, TrainingImg };





