import { Form, Modal } from "react-bootstrap";
import styles from "./SignUpDone.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/Images/ratinglogo.png";
import "../../assets/Styles/Common.css";
import { useTranslation } from "react-i18next";
import AppStore from "../../assets/Images/appstore.png";
import PlayStore from "../../assets/Images/playstore.png";
import { useDispatch, useSelector } from "react-redux";
import { clearForm, signUpCurrentUser } from "../../store/slices/UserSlice";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

function SignupDone({ isOpen, setIsOpen }) {
  const { signupData1, signupUser } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // --------function for open component top of the page ----------
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formdata) => {
    dispatch(clearForm());
  };

  const signUpUser = async () => {
    dispatch(signUpCurrentUser(signupUser));
    navigate("/profile");
  };

  return (
    <>
      <div className="maindone">
        <div>
          <Modal
            className="successModal"
            show={isOpen}
            onHide={() => setIsOpen(false)}
            backdrop="static"
          >
            <Modal.Body>
              <div className={styles.FormBlock}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className={styles.FormSection}>
                    <div className={styles.SignupTitle}>
                      <img src={Logo} alt="logo" />
                      <h2>{t("WELCOME_TEXT")}</h2>
                      <p>@{signupData1.userName}</p>
                    </div>
                    <div className={styles.signupPara}>
                      <h5>{t("CREATE_USER_ACCOUNT")}</h5>
                    </div>
                    <div className={styles.doneBtn}>
                      <button onClick={signUpUser}>{t("DONE_SHOW")}</button>
                    </div>
                    <div className={styles.GetApp}>
                      <p>{t("GET_THE_APP")}</p>
                      <div className={styles.AppLink}>
                        <NavLink
                          to={process.env.REACT_APP_APP_STORE_LINKS}
                          target="blank"
                        >
                          <img src={AppStore} alt="app store" />
                        </NavLink>
                        <NavLink
                          to={process.env.REACT_APP_PLAY_STORE_LINKS}
                          target="blank"
                        >
                          <img src={PlayStore} alt="play store" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default SignupDone;
