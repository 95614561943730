import { Col, Container, Row } from "react-bootstrap";
import styles from "../../../appComponents/Plan/Plan.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { STATUS_CODES } from "../../../utils/StatusCode";
import SublyApi from "../../../helpers/Api";
import { useNavigate, useParams } from "react-router-dom";
import HeaderSection from "../../HeaderSection/HeaderSection";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../utils/Loader/Loader";
import $ from "jquery";
import { Toast } from "../../../utils/Toaster";
import { userLogoutClear } from "../../../store/slices/UserSlice";

//-----function for plan image details----------
function PurchasePlanDetail() {
    const { t } = useTranslation();
    const { userToken } = useSelector((state) => state.user);
    const [planDetails, setPlanDetails] = useState("");
    const { id, type, trasId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    // --------function for open component top of the page ----------
    useEffect(() => {
        $("document").ready(function () {
            $(window).scrollTop(0);
        });
    }, []);


    // ---------function for workout plan details api calling---------- 
    useEffect(() => {
        async function getPlanDetails() {
            setIsLoading(true);
            const details = await SublyApi.planPurchaseDetails(id, type, trasId, userToken);
            setIsLoading(false);
            if (details && details.settings && details.settings.success && details.settings.success == STATUS_CODES.SUCCESS_API) {
                setPlanDetails(details && details.settings.data);
            } else if (details.settings.success == STATUS_CODES.INVALID_TOKEN) {
                Toast.fire({
                    icon: "error",
                    title: t("SESSION_EXPIRE"),
                });
                dispatch(userLogoutClear());
                navigate("/login");
            }
        }
        getPlanDetails();
    }, []);

    return (
        <>
            <div className="main">
                {isLoading === true ? <Loader /> : ""}
                <HeaderSection />
                {planDetails ?
                    <div className={styles.widthManages}>
                        <div className={styles.planDetailSection}>
                            <Container className="planContainer">
                                <Row>
                                    <Col lg={6} md={12} sm={12} className="containerHide">
                                        <div className={styles.planDetailImage}>
                                            <img src={planDetails.plan_image} alt="plan-image" />

                                            {planDetails &&
                                                planDetails.amount == "0" ?
                                                <div className={styles.lockIconDetail}>
                                                    <p>{t("FREE")}</p>
                                                </div> : <div className={styles.lockIconDetail}>
                                                    <span>{planDetails.currency_symbol}{planDetails.amount}</span>
                                                </div>}

                                            {planDetails && planDetails.show_in_app && planDetails.show_in_app.toLocaleLowerCase() == "no" ? "" : (
                                                planDetails.plan_name && (
                                                    <div className={styles.titleSet}>
                                                        <h2>{planDetails.plan_name}</h2>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </Col>
                                    <Col lg={6} md={12} sm={12}>
                                        <div className={styles.planDetailRight}>
                                            {planDetails.plan_level && (
                                                <>
                                                    <div className={styles.detailsOptions}>
                                                        <h2>{planDetails.plan_level}</h2>
                                                        <p>{t("FITNESS_LEVEL")}</p>
                                                    </div>
                                                    {(planDetails.good_for_name == "" && planDetails.equipment_name == "" && planDetails.description == "") ?
                                                        "" :
                                                        <div className={styles.borderSet}>
                                                        </div>}
                                                </>
                                            )}

                                            {planDetails.good_for_name && (
                                                <>
                                                    <div className={styles.detailsOptions}>
                                                        <h2>{t("GOOD_FOR")}</h2>
                                                        <p>{planDetails.good_for_name}</p>
                                                    </div>
                                                    {(planDetails.equipment_name == "" && planDetails.description == "") ?
                                                        "" :
                                                        <div className={styles.borderSet}>
                                                        </div>}
                                                </>
                                            )}

                                            {planDetails.equipment_name && (
                                                <>
                                                    <div className={styles.detailsOptions}>
                                                        <h2>{t("EQUIPMENT")}</h2>
                                                        <p>{planDetails.equipment_name}</p>
                                                    </div>
                                                    {planDetails.description == "" ?
                                                        "" :
                                                        <div className={styles.borderSet}>
                                                        </div>
                                                    }
                                                </>
                                            )}

                                            {planDetails.description &&
                                                <div className={`${styles.detailsOptions} ${styles.endOption}`}>
                                                    <h2>{t("OVERVIEW")}</h2>
                                                    <p>{planDetails.description}</p>
                                                </div>
                                            }

                                            <div className={styles.purchaseButton}>
                                                <button>
                                                    {t("PURCHASED")}
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div> : ""}
            </div>
        </>
    );
}
export default PurchasePlanDetail;
