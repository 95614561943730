const LBS = [
  // { value: "0", label: "LBS" },
];
for (let i = 30; i <= 500; i++) {
  LBS.push({ value: i, label: i });
}

const KG = [
  // { value: "0", label: "KG" },
];
for (let i = 13; i <= 227; i++) {
  KG.push({ value: i, label: i });
}

const FT = [
  // { value: "0", label: "FT" },
];
for (let i = 1; i <= 7; i++) {
  FT.push({ value: i, label: i });
}

const IN = [
  // { value: "0", label: "IN" },
];
for (let i = 1; i <= 11; i++) {
  IN.push({ value: i, label: i });
}

const CM = [
  // { value: "0", label: "CM" },
];
for (let i = 1; i <= 242; i++) {
  CM.push({ value: i, label: i });
}

export { LBS, KG, FT, CM, IN };
