import { Container } from "react-bootstrap";
import styles from "../../appComponents/HomePage/HomePage.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import "../../assets/Styles/Common.css";

function HomeSliderImg({ packageList }) {
  const { t } = useTranslation();
  // Configure the slider settings
  const settings = {
    arrowLeft: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 5,
    autoplay: false,
    centerPadding: "100px",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={styles.main}>
      <Container className="homeContent">
        {packageList &&
          packageList.get_success_story &&
          packageList.get_success_story.length > 0 &&
          <div className={styles.storiesImg}>
            <div className={styles.imgslider}>
              <div className="homefitnessSlider">
                <Slider {...settings}>
                  {packageList &&
                    packageList.get_success_story &&
                    packageList.get_success_story.length > 0
                    ? packageList.get_success_story.map((item, index) => (
                      <div key={index} style={{ maxWidth: "100%" }}>
                        <img src={item.image} alt={`Slide ${index + 1}`} />
                      </div>
                    ))
                    : ""}
                </Slider>
              </div>
            </div>
          </div>}
      </Container>
    </div>
  );
}

export default HomeSliderImg;
