//---------------- Header Text----------------------
export const TRANSLATIONS_EN = {
  PLAN_TEXT: "Plan",
  SUBSCRIPTION: "Subscription",
  LOGIN: "Login",
  SIGN_UP: "Sign Up",

  //---------------- Home Text----------------------
  SIGN_UP_BTN_FREE: " Sign up for free",
  PROJECT_HEADING: "Doviesfitness",
  FITNESS_HEADING: "SUCCESS STORIES",

  //---------------- Footer Text----------------------
  CONTACT_US: "Contact us",
  TERMS_CONDITION: "Terms And Conditions",
  PRIVACY_POLICY: "Privacy Policy",
  COPYRIGHT: "Copyright",
  ALLRIGHTRESERVED: "Dovies Limited. All rights reserved.",

  //---------------- page not found Text----------------------
  TITTLE_TEXT: "Oops !",
  PAGE_NOT_FOUND: "404 - Page Not Found",
  PAGE_PARA: "Sorry, the page you were looking for could not be found",

  //---------------- contactUS Text----------------------
  CONTACT_HEADING1: " WE WOULD LOVE TO",
  CONTACT_HEADING2: "HERE FROM YOU.",
  CONTACT_PARA: `Have a question or enquiries about Doviesfitness? Have a great
     idea or how to improve our app? Or you are having any issues?
     send us a message and we will get back to you as soon as
     possible.`,

  //---------------- contactUS Form label and placeholder Text----------------------
  NAME: "Name",
  EMAIL_ADDRESS: " Email Address",
  FEEDBACK_TYPE: " Subject / Feedback Type",
  OPTION1: "General Feedback",
  OPTION2: "Report An Issue",
  OPTION3: "Advertiesment And Promo",
  MESSAGE: " Message / Feedback ",
  SUBMIT_BTN: "Submit",
  NAME_FIELD: "Enter Name",
  EMAIL_FIELD: "Enter Email",
  REQUIRED_FIELD: "Please fill required field",
  FORGOT_REQUIRED_FIELD: "Please enter your email address.",
  MESSAGE_FIELD: "Please enter message.",
  ENTER_EMAIL: "Enter Email Address",
  ENTER_NAME: "Please enter your name.",

  //---------------- Forgot password Text----------------------
  FORGOT_HEADING: "Reset Your password",
  FORGOT_PARA: `  Enter your email to receive instructions on how to reset
your password.`,
  RESET_BTN: "Reset",
  RETURN_PARA: "Or return to ",
  LOGIN_OPTION: "Log In ",
  UPDATE_PASSWORD: "UPDATE PASSWORD",
  NEW_PASSWORD: "Please enter your new password.",
  ENTER_PASSWORD: "Enter Password",

  //---------------- Signup form----------------------
  CREATE_ACCOUNT: "CREATE ACCOUNT",
  DOVIESFITNESS: "Doviesfitness",
  ACCOUNT_TEXT: "Account",
  SIGN_UP_WITH: "Sign Up With Facebook",
  SIGN_UP_WITH_GOOGLE: "Sign up With Google",
  FULLNAME: "Full Name",
  EMAIL: "Email",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: " Confirm Password",
  SIGNUP_TERMS: "By creating an account, you agree to Doviesfitness",
  AND_OR: "and",
  TERMS_OF_USE: "Terms Of Use. ",
  CREATE_YOUR: "CREATE YOUR",
  HAVE_AN_ACCOUNT: " Do you have an account?",
  LOG_IN: "Sign in",
  GET_THE_APP: "Get the DoviesFitness app",
  INVALID_PASSWORD: "please enter correct password",
  INVALID_EMAIL: "Please enter a valid email address.",
  MATCH_PASS: "Passwords have to match",
  FULLNAME_REQUIRED: "Please enter your full name.",
  EMAIL_REQUIRED: "Please enter your email address.",
  CONFIRM_PASSWORD_REQUIRED: " Please enter confirm password.",
  GENDER_HEADING: "WHAT'S YOUR GENDER? ",
  EXISTS_EMAIL: "Email already exists",
  DONE: "Done",
  DONE_SHOW: "DONE",
  ENTER_USERNAME: "Please enter username.",
  USERNAME_WRONG:
    "Usernames can only use letters, numbers, underscores and periods. (You can't start OR end your username with a period.)",
  USERNAME_LENGTH:
    "Please enter your username, with a minimum of 3 characters.",
  USERNAME_MAXLENGTH: "Please enter your username, with a maximum of 30 characters.",
  NAME_MAXLENGTH: "Please enter your full name, with a maximum of 30 characters.",
  PASS_MAXLENGTH: "Please enter password, with a maximum of 30 characters.",
  PASS_MINLENGTH: "Please enter password, with a minimum of 5 characters.",
  //---------------- LOGIN form----------------------

  LOGIN_WITH_FACEBOOK: "Sign in with Facebook",
  SIGN_IN_WITH_GOOGLE: "Sign in with Google",
  USERNAME: "Email or Username",
  FORGOT_PASSWORD: "Forgot Password?",
  LOGIN_TERMS: "By loging in , you agree to Doviesfitness's",
  JOIN_US: "Join Us",
  NOT_A_MEMBER: "Not a member?",
  USERNAME_ALREADY: "username already exit",
  EMAIL_ALREADY: "Email already exit",
  SIGN_IN: " SIGN IN WITH USERNAME OR EMAIL",
  SIGN_IN_BUTTON: "SIGN IN",

  //--------------new home page text-------------------
  ABOUT: "About us",
  FAQ: "FAQ",
  TERMS: "Terms & Conditions",
  POLICY: "Privacy policy",
  TRAINER_NAME1: "Rick Wright",
  TRAINER_NAME2: "Dovies",
  TRAINER_NAME3: "App User",
  HEADING: "LET’S TRANSFORM TOGETHER",
  PARA: "We are here to inspire and motivate you, So come train join the Doviesfitness team",
  HEADING1: "JOIN THE DOVIESFITNESS TEAM",
  LONG_PARA: `Doviesfitness app is more than just an app.  It’s a community of people who share the same passion for fitness and health. Our goal is to help you achieve the lifestyle you deserved. Join ten’s of thousands daily users who enjoy the doviesfitnes workout and diet plans the app offers. And if you need any help we will be available to help you with all your fitness`,
  BOTTOM_PARA: ` Doviesfitness app is best for anyone who want to workout at
  home or gym. with Doviesfitness app you get • Access over 1800
  exercise Demonstration videos to show you how to perform each
  exercise correctly and safely. You also get Access to over 200
  workouts you can do at home or at gym. There are workout plans
  for weight loss, toning, or muscle gains and diet plans for
  vegan, pescatarian, ketogenic, and more. The app is designed
  to help you rack your workouts and see your progress over time
  with our workout log feature and you can enjoy 4K Ultra HD
  on-demand workouts for a stunning viewing experience with the
  option of offline downloads to enjoy your workout from any
  where.`,

  HEADING2: "APP USERS TRANSFORMATIONS",
  USERNAME_REQUIRED: "Please enter your username or email address.",
  PASSWORD_REQUIRED: "Please enter password.",
  HOME_RATING: "4.9",
  HOME_REWIEV: "(478)",

  //--------------new home page text-------------------
  CREATE_USERNAME: "CREATE USERNAME",
  CREATE_PARA: `Pick a username for your account. You can always change it
later.`,
  PROFILE_PIC: "ADD PROFILE PHOTO",
  PROFILE_PARA: " Add a profile photo so that your friends know it's you.",
  NEXT_BUTTON: "Next",

  //---------------subscription detail page text----------
  SUBSCRIPTION_DETAIL_SUMMARY: "Subscription Details",
  PRICE_TEXT: "Price",
  TOTAL_PRICE: "Total",
  SUBSCRIPTION_NOTE: " Note:",
  SUBSCRIPTION_NOTE_PARA: `After payment, you must log in with your username and password
  from your ios or Android device to access everything on the
  app. This page is only for making payments for either
  subscription or exclusive content on the Doviesfitness app.
  You cannot view any other content from here. All payments are
  processed by paypal. You can pay with your Visa, Debit, or
  Mastercard through paypal , even if you don't have a paypal
  account.`,
  POWERED_BY: "Powered by",
  STARTS: "Starts on",
  RENEWS: "Renews until you cancel",
  START: "Start",
  THEN: "Then",
  PAYMENT: "One time payment",
  LIFETIME: "for a lifetime",
  FOR_EACH: "for each",
  AFTER_TRIAL: "after the trial period",
  EACH: "each",
  FOR: "for a",
  TRIAL: "trial",

  //---------------subscription data popup page text----------
  PLAN_TYPE: "Plan Type",
  PURCHASE_DATE: "Purchase Date",
  END_DURATION: "Ends In",
  NEXT_BILL_DATE: "Next Bill Date",
  NEXT_BILLING_AMOUNT: "Next Billing Amount",
  AUTO_REQURING: "Stop Auto Recurring",
  SUBSCRIBE: "Subscribe",
  CANCEL_SUBSCRIPTION_BUTTON: "Cancel Subscription",
  SUBSCRIPTION_CANCEL: "Subscription Canceled",


  //---------------about page text----------
  MALE: "Male",
  OPTIONAL_TEXT: "(Optional)",
  HEIGHT: "How Tall Are You?",
  WEIGHT: "WHAT'S YOUR WEIGHT?",
  FT: "FT",
  CM: "CM",
  LBS: "LBS",
  KG: "KG",
  IN: "IN",
  ABOUT_ERROR_MESSAGE: "Please select gender",

  //---------------signupDone page text----------
  WELCOME_TEXT: "Welcome To DoviesFitness",
  CREATE_USER_ACCOUNT:
    "Use these login credentials to access the dovies fitness. There you can create and share your workout with friends and family. You can also enjoy a varity of workouts and plans on the app. ",

  //---------------profile text----------
  DATE_OF_BIRTH: "Date Of Birth",
  PROFILE_FULLNAME: "FullName",
  PROFILE_USERNAME: " Username",

  PROFILE_DOB: " Date Of Birth",
  DOB_FIELD_TEXT: "02/07/1996",
  COUNTRY_TEXT: "Country",
  COUNTRY_NAME: "India",
  PROFILE_GENDER: "Gender",
  FEMALE: "Female",
  PROFILE_HEIGHT: "Height",
  PROFILE_WEIGHT: "Weight",
  PROFILE_SUBSCRIPTION: "You Don't Have any subscription",
  PROFILE_PRIMIUM: "Upgrade to Premium",
  PROFILE_OLD_PASSWORD: "Old Password",
  PROFILE_NEW_PASSWORD: " New password",
  PROFILE_CONFIRM_PASSWORD: "Confirm Password",
  UPDATE_BTN: "Update",
  CANCEL_BTN: "Cancel",
  LOGOUT_BTN: "Logout",
  MONTH: "Month",
  DAY: "Day",
  YEAR: "Year",
  SELECT_COUNTRY: "Please select country",
  NOT_MATCH: "Password does not match the confirm password.",
  OLD_PASS: "Please enter your old password.",
  NEW_PASS: "Please enter new password.",
  CONFIRM_PASS: "Please enter confirm password.",
  UNITS: " Units",
  METRIC: "Metric",
  IMPERIAL: "Imperial",

  //---------- Status Error Text---------------
  SESSION_EXPIRE: "Your Session Expired!",

  //---------------- PlanSection Text----------------------
  PLAN_HEADING: "CHOOSE A PLAN",
  PLAN_PARA: `Get started on your fitness goals with our plans. Build muscles, Tone, Burn some fat and get the best shape ever. Any plan 
  you chose here can be edited according to your goals.
  All plans come with diet as well so you will have your workout plan and diet plan in one place. Just add this to your Plan 
  and log in to the app and enjoy.`,
  LOADMORE_BUTTON: "Load More",
  BUY: "BUY",
  // SALE: "Sales",
  SALE: "Exclusive",
  FITNESS_LEVEL: "Fitness Level",
  GOOD_FOR: "Good For",
  EQUIPMENT: "Equipment",
  OVERVIEW: "Overview",
  // SALE_SUMMARY: "Sales Summary",
  SALE_SUMMARY: "Exclusive Summary",
  About_Us: "About Us",
  PURCHASE_TEXT: "PURCHASED",
  EXCLUSIVE: "Exclusive",
  ADD_PURCHASE_TEXT: "You have not added any plan yet. Click here to explore our plan or go to our app.",
  ADD_PLAN_BTN: " ADD PLAN",
  PREVIOUS: "Previous",
  PURCHASED_DATE: "Purchased Date",
  SUBSCRPTION_EXPIRE: "Subscription Duration",
  SUBSCRIPTION_IN: "Subscription Expires In",
  PREMINUM: "Doviesfitness Premium",
  CANCEL_TEXT: `If you cancel now, you can still access your subscription until `,
  FREE: "Free",
};
