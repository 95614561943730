import { Container, Form } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toaster";
import Logo from "../../assets/Images/ratinglogo.png";
import styles from "../ForgotPassword/ForgotPassword.module.css";
import HeaderSection from "../../appComponents/HeaderSection/HeaderSection";
import { STATUS_CODES } from "../../utils/StatusCode";

// ---------function for reset password ------------
function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const passwordKeyPress = (e) => {
    setValue(e.target.name,e.target.value.replace(/\s/g, '')); // this remove from all string
    // setValue(e.target.name,e.target.value.trim());// this remove space only from left and right
  };


  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("code", code);
    requestData.append("new_password", formdata.password);
    await SublyApi.resetPasword(requestData).then((responsejson) => {
      if (responsejson.settings.success == STATUS_CODES.SUCCESS_API) {
        setValue("password", "");
        Toast.fire({
          icon: "success",
          title: responsejson.settings.message,
        });
        navigate("/login");
      } else {
        Toast.fire({
          icon: "error",
          title: responsejson.settings.message,
        });
      }
    });
  };

 
  return (
    <>
      <div className="main">
        {/* <HeaderSection/> */}
        <section className={styles.resetPasswordSet}>
          <Container>
            <div className={styles.FormBlock}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.FormSection}>
                  <div className={styles.SignupTitleSet}>
                    <NavLink to="/">
                      <img src={Logo} alt="logo" />
                    </NavLink>
                    <h2>{t("UPDATE_PASSWORD")}</h2>
                    <p>{t("NEW_PASSWORD")}</p>
                  </div>

                  <div>
                    <Form.Group className={styles.FormGroup}>
                      <Form.Control
                        className={styles.FormInput}
                        placeholder={t("ENTER_PASSWORD")}
                        type="password"
                        {...register("password", {
                          onChange:(e) => {
                            passwordKeyPress(e);
                        },
                          required: {
                            value: true,
                            message: `${t("PASSWORD_REQUIRED")}`,
                          },
                        })}
                      />

                      {errors.password && (
                        <span className="errorMsg">
                          {errors.password.message}
                        </span>
                      )}
                    </Form.Group>

                    <div className={styles.signUpBtn}>
                      <button onClick={() => handleSubmit}>
                        {t("UPDATE_PASSWORD")}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
}

export default ResetPassword;
