import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import styles from "../../../appComponents/Plan/Plan.module.css";
import { ACCESS_LEVEL, PLAN } from "../../../utils/Constants";
import { BsCurrencyDollar } from "react-icons/bs";
import { useSelector } from "react-redux";
import $ from "jquery"

//------function for plan section images----------
function FitnessPlan() {
  const { workoutPlanList } = useSelector((state) => state.planList);

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);


  return (
    <div className={styles.homePlan}>
      {workoutPlanList && workoutPlanList.length > 4 ? (
        <div className={styles.image_grid}>
          {workoutPlanList && workoutPlanList.length > 0
            ? workoutPlanList.map((item, index) => (
              <NavLink
                to={`/exclusive/details/${item.id}/${item.plan_type == "workout_plan"
                  ? PLAN.WORKOUT_PLAN
                  : PLAN.STREAM_WORKOUT_PLAN
                  }`}
                key={index}
              >
                <div className={styles.itemSet}>
                  <div className={styles.planImg}>
                    <img
                      src={item.plan_image}
                      alt="plan image"
                      className={styles.imageItem}
                    />
                    {item.acess_level == ACCESS_LEVEL.LOCK ? (
                      <div className={styles.lockIcon}>
                        <BsCurrencyDollar />
                      </div>
                    ) : (
                      ""
                    )}

                    {item.show_in_app && item.show_in_app.toLocaleLowerCase() == "no" ? (
                      ""
                    ) : item.plan_name && (
                      <div className={styles.nameOnImage}>
                        <h6>{item.plan_name}</h6>
                      </div>
                    )}
                  </div>
                </div>
              </NavLink>
            ))
            : ""}
        </div>
      ) : (
        <div className={styles.image_middle}>
          {workoutPlanList && workoutPlanList.length > 0
            ? workoutPlanList.map((item, index) => (
              <NavLink
                to={`/exclusive/details/${item.id}/${item.plan_type == "workout_plan"
                  ? PLAN.WORKOUT_PLAN
                  : PLAN.STREAM_WORKOUT_PLAN
                  }`}
              >
                <div key={index} className={styles.itemSet}>
                  <div className={styles.planImg}>
                    <img
                      src={item.plan_image}
                      alt="plan image"
                      className={styles.imageItem}
                    />
                    {item.acess_level == ACCESS_LEVEL.LOCK ? (
                      <div className={styles.lockIcon}>
                        <BsCurrencyDollar />
                      </div>
                    ) : (
                      ""
                    )}

                    {item.show_in_app && item.show_in_app.toLocaleLowerCase() == "no" ? (
                      ""
                    ) : item.plan_name && (
                      <div className={styles.nameOnImage}>
                        <h6>{item.plan_name}</h6>
                      </div>
                    )}
                  </div>
                </div>
              </NavLink>
            ))
            : ""}
        </div>
      )}
    </div>
  );
}

export default FitnessPlan;
