import styles from "./GoogleSocialLogin.module.css";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SOCIAL_NETWORK_TYPE } from "../../utils/Constants";
import { checkSocialLogin } from "../../store/slices/UserSlice";
import { Toast } from "../../utils/Toaster";
import { useEffect, useState } from "react";
import google from "../../assets/Images/google_logo.png";
import axios from "axios";

//---------function for google social api call----------
function GoogleSocial({ loadFrom, setSocialDetail }) {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState([]);

    // --------function for open component top of the page ----------
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


  // Social Login with google
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          responseGoogle(res.data);
        })
        .catch((err) => err);
    }
  }, [user]);

  // Social Login with Google
  const responseGoogle = async (response) => {
    let userData = response;
    if (userData) {
        console.log('userData',userData)
        let userProfile = (userData.picture) ? userData.picture : "";
        console.log('userProfile',userProfile)
        dispatch(checkSocialLogin({ id: userData.id, social_network_type: SOCIAL_NETWORK_TYPE.SOCIAL_NETWORK_GOOGLE })).then((responsejson) => {
        const response = responsejson.payload;
        if (response && response.settings.success == '1') {
            Toast.fire({
                icon: "success",
                title: response.settings.message,
            });
            navigate("/profile")
        } else if (response && response.settings.success == '2') {
            if ((loadFrom) && loadFrom == 'signup') {
                setSocialDetail({
                    name: userData.name,
                    email: userData.email,
                    picture: userProfile,
                    social_network_id: userData.id,
                    social_network_type: SOCIAL_NETWORK_TYPE.SOCIAL_NETWORK_GOOGLE,
                    isSocialLogin: true,
                })
            } else {
                navigate("/sign-up", {
                    state: {
                        name: userData.name,
                        email: userData.email,
                        picture: userProfile,
                        social_network_id: userData.id,
                        social_network_type: SOCIAL_NETWORK_TYPE.SOCIAL_NETWORK_GOOGLE,
                        isSocialLogin: true,
                    },
                });
            }

        }
        else {
            Toast.fire({
                icon: "error",
                title: response && response.settings.message,
            });
        }
    });
    }
  };

  return (
    <>
      <div className={styles.SocialLoginBtn}>
        <button onClick={() => login()}>
            <img src={google} alt="google"  />
            {location.pathname == "/login" ?
                                <p>{t("SIGN_IN_WITH_GOOGLE")}</p> : <p>{t("SIGN_UP_WITH_GOOGLE")}</p>}
        </button>
      </div>
    </>
  );
}
export default GoogleSocial;
