import { t } from "i18next";

export const FooterData1 = [
    {
        id: "1",
        text: `${t("CONTACT_US")} `,
        link: `/contact-us`,
    },
    {
        id: "2",
        text: `${t("TERMS")} `,
        link: `/terms-condition`,
    },
    {
        id: "3",
        text: `${t("POLICY")}`,
         link: `/privacy-policy`,
    },
    {
        id: "4",
        text: `${t("ABOUT")}`,
         link: `/about-us`,
      
    },
    {
        id: "5",
        text: `${t("FAQ")} `,
        link: `/faq`,
    },
];