export const heightFtInToCm = (selectFt, selectIn) => {
  const totalInches = parseInt(selectFt) * 12 + parseInt(selectIn);
  const cm = (totalInches * 2.54).toFixed(2);
  return cm;
};

//----for convert lbs to kg------
export const weightLbsToKg = (lbs) => {
  return parseInt(lbs) * 0.45359237;
};
