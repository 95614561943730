import { CONTENT } from "../../utils/Constants";
import { Container } from "react-bootstrap";
import styles from "../Content/Content.module.css";
import SublyApi from "../../helpers/Api";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

//----------function for terms and condition content-----------
function TermsCondition() {
  const { t } = useTranslation();
  const [contentList, setContentList] = useState("");

  //---------function for get terms and condition content api calling----------
  useEffect(() => {
    async function privacyContent() {
      const details = await SublyApi.getContent(CONTENT.TERMS_CONDITION);
      details && setContentList(details.data.page_content);
    }
    privacyContent();
  }, []);

  return (
    <>
      {contentList && (
        <div className="main">
          <div className={styles.contentText}>
            <Container>
              <h2>{t("TERMS_CONDITION")}</h2>
              <div
                className="textSection"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(contentList),
                }}
              ></div>
            </Container>
          </div>
        </div>
      )}
    </>
  );
}
export default TermsCondition;
