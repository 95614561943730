import { Accordion, Container } from "react-bootstrap";
import styles from "../FaqPage/FaqPage.module.css";
import SublyApi from "../../helpers/Api";
import { useEffect, useState } from "react";
import { newlineText } from "../../commonComponents/NewLineText";
import HeaderSection from "../HeaderSection/HeaderSection";
import { useTranslation } from "react-i18next";
import Loader from "../../utils/Loader/Loader";

//----------function for FAQ-----------
function FaqPage() {
  const { t } = useTranslation();
  const [faqList, setFaqList] = useState("");
  const [loader, setLoader] = useState(false);

  //---------function for get FAQ list api calling----------
  useEffect(() => {
    async function faqListGet() {
      setLoader(true);
      const details = await SublyApi.getFaqList();
      setLoader(false);
      details && setFaqList(details.data);
    }
    faqListGet();
  }, []);

  return (
    <>
      {loader ? <Loader /> : ""}
      <div className={styles.faqSection}>
        <HeaderSection />
        {faqList && (
          <div className={styles.faqText}>
            <Container>
              <h3>{t("FAQ")}</h3>
              <div className="faqBlock">
                <Accordion>
                  {faqList &&
                    faqList.map((item, index) => (
                      <Accordion.Item eventKey={item.faq_id} key={index}>
                        <Accordion.Header>{item.faq_question}</Accordion.Header>
                        <Accordion.Body>
                          {newlineText(item.faq_answer)}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </div>
            </Container>
          </div>
        )}
      </div>
    </>
  );
}
export default FaqPage;
