import { Container } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderSection from "../HeaderSection/HeaderSection";
import styles from "../Subscription/SubscriptionDetail.module.css";
import "../../assets/Styles/Common.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import paypalImage from "../../assets/Images/paypal.svg";
import { useEffect } from "react";
import visa from "../../assets/Images/paymentmethods.png";
import { useTranslation } from "react-i18next";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { STATUS_CODES } from "../../utils/StatusCode";
import { Toast } from "../../utils/Toaster";
import { updateSubcription, userLogoutClear } from "../../store/slices/UserSlice";
import { SESION_TRANSACTION_TYPE } from "../../utils/Constants";

function SubscriptionDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const details = location.state;
  const { userToken } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formattedDate = new Date().toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  useEffect(() => {
    if (details === null) {
      navigate("/subscription");
    }
  }, [details]);


  //-----------custom styling for paypal button------------
  const buttonStyle = {
    layout: "horizontal",
    color: "blue",
    shape: "pill",
    label: "buynow", // Set the label text here
    size: "medium",
    tagline: false,
    height: 45,
  };

  // ---------manage session api calling----------------------
  async function ManageSessionExpire() {
    const requestData = new FormData();
    requestData.append("transaction_type", SESION_TRANSACTION_TYPE.Subscription);
    requestData.append("plan_id", details && details.packageDetail && details.packageDetail.id);
    return await SublyApi.sessionManage(requestData, userToken).then((response) => {
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        return true;
      }
      else if (response && response.settings && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: t("SESSION_EXPIRE"),
        });
        dispatch(userLogoutClear());
        navigate("/login");
      } else {
        return false;
      }
    }
    );
  }

  //---------creates a paypal order for lifetime subscription-----------
  const createOrder = async (data, actions) => {
    const res = await ManageSessionExpire();
    if (res) {
      return actions.order
        .create({
          purchase_units: [
            {
              amount: {
                currency_code: details.packageDetail && details.packageDetail.subcription_currency,
                value: details.packageDetail && details.packageDetail.subscription_amount,
              },
            },
          ],
        })
        .then((orderID) => {
          // setOrderID(orderID);
          return orderID;
        });
    } else {
      return false;
    }
  };

  async function checkOutHandleLifetime(data) {
    const requestData = new FormData();
    requestData.append("package_master_id", details.packageDetail.id);
    requestData.append("amount", details && details.packageDetail.subscription_amount);
    requestData.append("profile_id", data.purchase_units[0].payments.captures[0].id);
    requestData.append("order_id", data.id);
    requestData.append("payment_json", JSON.stringify(data));
    await SublyApi.subscriptionCheckOut(requestData, userToken).then(
      (response) => {
        // console.log('checkOutHandleLifetime res',response)
        if (response.success == STATUS_CODES.SUCCESS_API) {
          dispatch(updateSubcription({ is_subscribed: 1 }));
          Toast.fire({
            icon: "success",
            title: response.message,
          });
          navigate("/profile/1");
        } else if (response && response.settings && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: t("SESSION_EXPIRE"),
          });
          dispatch(userLogoutClear());
          navigate("/login");
        } else {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
        }
      }
    );
  }

  // when create order for life time 
  const onApprove = (data, actions) => {
    return actions.order.capture().then(async function (paymentData) {
      checkOutHandleLifetime(paymentData);
    });
  };

  const paypalSubscribe = async (data, actions) => {
    const res = await ManageSessionExpire();
    if (res) {
      return actions.subscription.create({
        plan_id: details.packageDetail.package_id,
      });
    } else {
      return false;
    }
  };

  const paypalOnError = (err) => {
    // console.log('paypalOnError res',err)
  };

  const paypalOnApprove = (data, detail) => {
    checkOutHandle(data);
  };

  async function checkOutHandle(data) {
    const requestData = new FormData();
    requestData.append("package_master_id", details.packageDetail.id);
    // requestData.append("amount", details.packageDetail.subscription_amount);
    requestData.append("amount", details && details.packageDetail
      ? (details.packageDetail.free_trial == 1
        ? details.packageDetail.subscription_amount
        : details.packageDetail.trial_amount)
      : null);

    requestData.append("profile_id", data.subscriptionID);
    requestData.append("order_id", data.orderID);
    requestData.append("payment_json", JSON.stringify(data));
    await SublyApi.subscriptionCheckOut(requestData, userToken).then(
      (response) => {
        // console.log('checkOutHandle res',response)
        if (response.success == STATUS_CODES.SUCCESS_API) {
          dispatch(updateSubcription({ is_subscribed: 1 }));
          Toast.fire({
            icon: "success",
            title: response.message,
          });
          navigate("/profile/1");
        } else if (response && response.settings && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: t("SESSION_EXPIRE"),
          });
          dispatch(userLogoutClear());
          navigate("/login");
        } else {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
        }
      }
    );
  }

  return (
    <>
      <HeaderSection />
      <div className="main">
        <Container>
          {
            details !== null ?
              <div className={styles.subDetail}>
                {(details.packageDetail.subscription_duration == '4') ? (
                  <div className={styles.subscriptionPayBox}>
                    <div className={styles.summaryText}>
                      <h2>{t("SUBSCRIPTION_DETAIL_SUMMARY")}</h2>
                    </div>
                    <div className={styles.payText}>
                      <p>{details.packageDetail.currency_symbol}
                        {details.packageDetail.subscription_amount} {details.packageDetail.subcription_currency} {t("LIFETIME")}
                        <br></br>
                        ({t("PAYMENT")})
                        <br></br>
                        <div className={styles.dateShow}>
                          {t("STARTS")} : {formattedDate}
                        </div>
                      </p>
                    </div>
                    <div className={styles.timeText}>
                      <p>{details.packageDetail.package_name}</p>
                      <p>
                        {details.packageDetail.currency_symbol}
                        {details.packageDetail.subscription_amount} {details.packageDetail.subcription_currency}
                      </p>
                    </div>
                    <div className={styles.priceText}>
                      <p>{t("TOTAL_PRICE")}</p>
                      <p>
                        {details.packageDetail.currency_symbol}
                        {details.packageDetail.subscription_amount} {details.packageDetail.subcription_currency}
                      </p>
                    </div>
                  </div>
                ) : (details && details.packageDetail && details.packageDetail.free_trial == 1 ?
                  (
                    <div className={styles.subscriptionPayBox}>
                      <div className={styles.summaryText}>
                        <h2>{t("SUBSCRIPTION_DETAIL_SUMMARY")}</h2>
                      </div>
                      <div className={styles.payText}>
                        <p>{details.packageDetail.currency_symbol}
                          {details.packageDetail.subscription_amount} {details.packageDetail.subcription_currency} {t("FOR_EACH")} {details.packageDetail && details.packageDetail.duration == "1" ? "" : details.packageDetail.duration} { }
                          {details.packageDetail && details.packageDetail.duration > 1
                            ? (details.packageDetail && details.packageDetail.duration_type.toLowerCase()) + 's'
                            : (details.packageDetail && details.packageDetail.duration_type.toLowerCase())}
                          {details.packageDetail && details.packageDetail.duration_cycle > 1 ?
                            `, for ${details.packageDetail.duration_cycle} installments` :
                            ""
                          }
                          <br></br>
                          ({t("RENEWS")})
                          <br></br>
                          <div className={styles.dateShow}>
                            {t("STARTS")} : {formattedDate}
                          </div>
                        </p>
                      </div>
                      <div className={styles.timeText}>
                        <p>{details.packageDetail.package_name}</p>
                        <p>
                          {details.packageDetail.currency_symbol}
                          {details.packageDetail.subscription_amount} {details.packageDetail.subcription_currency}
                        </p>
                      </div>
                      <div className={styles.priceText}>
                        <p>{t("TOTAL_PRICE")}</p>
                        <p>
                          {details.packageDetail.currency_symbol}
                          {details.packageDetail.subscription_amount} {details.packageDetail.subcription_currency}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.subscriptionPayBox}>
                      <div className={styles.summaryText}>
                        <h2>{t("SUBSCRIPTION_DETAIL_SUMMARY")}</h2>
                      </div>
                      <div className={styles.payText}>
                        <p>
                          <span>
                            {t("START")} :
                          </span> {details.packageDetail.currency_symbol}
                          {details.packageDetail.trial_amount} {details.packageDetail.subcription_currency} {t("FOR")} {details.packageDetail.trial_duration}-
                          {details.packageDetail && details.packageDetail.trial_duration > 1
                            ? (details.packageDetail && details.packageDetail.trial_duration_type.toLowerCase()) + 's'
                            : (details.packageDetail && details.packageDetail.trial_duration_type.toLowerCase())} {t("TRIAL")}
                          <br></br>
                          <span>
                            {t("THEN")} :
                          </span> {details.packageDetail.currency_symbol}
                          {details.packageDetail.subscription_amount} {details.packageDetail.subcription_currency} {t("EACH")} {details.packageDetail && details.packageDetail.duration == "1" ? "" :
                            details.packageDetail.duration} { }
                          {details.packageDetail && details.packageDetail.duration > 1
                            ? (details.packageDetail && details.packageDetail.duration_type.toLowerCase()) + 's'
                            : (details.packageDetail && details.packageDetail.duration_type.toLowerCase())} {t("AFTER_TRIAL")} {details.packageDetail && details.packageDetail.duration_cycle > 1 ?
                              `, for ${details.packageDetail.duration_cycle} installments` :
                              ""
                          }
                          <br></br>
                          ({t("RENEWS")})
                          <br></br>
                          <div className={styles.dateShow}>
                            {t("STARTS")} : {formattedDate}
                          </div>
                        </p>
                      </div>
                      <div className={styles.timeText}>
                        <p>{details.packageDetail.package_name}</p>
                        <p>
                          {details.packageDetail.currency_symbol}
                          {details.packageDetail.trial_amount} {details.packageDetail.subcription_currency}
                        </p>
                      </div>
                      <div className={styles.priceText}>
                        <p>{t("TOTAL_PRICE")}</p>
                        <p>
                          {details.packageDetail.currency_symbol}
                          {details.packageDetail.trial_amount} {details.packageDetail.subcription_currency}
                        </p>
                      </div>
                    </div>
                  ))}

                <div className={styles.subscriptionDetailText}>
                  <p>
                    {t("SUBSCRIPTION_NOTE")} { }
                    <span>{t("SUBSCRIPTION_NOTE_PARA")}</span>
                  </p>
                </div>

                <div className={styles.backButton}>
                  <div className={styles.payPal}>
                    <PayPalScriptProvider
                      options={{
                        vault: true,
                        clientId: process.env.REACT_APP_CLIENT_ID,
                      }}
                    >
                      {(details.packageDetail.subscription_duration == '4') ? <PayPalButtons
                        createOrder={createOrder}
                        onApprove={onApprove}
                        style={buttonStyle}
                      /> : <PayPalButtons
                        style={buttonStyle}
                        amount={details.packageDetail.subscription_amount}
                        currency={details.packageDetail.subcription_currency}
                        createSubscription={paypalSubscribe}
                        onApprove={paypalOnApprove}
                        catchError={paypalOnError}
                        onError={paypalOnError}
                        onCancel={paypalOnError}
                      />}

                    </PayPalScriptProvider>
                  </div>
                </div>
                <div className={styles.payPalImg}>
                  <img src={visa} alt="logo" />
                </div>
                <div className={styles.poweredImg}>
                  <h4>{t("POWERED_BY")}</h4>
                  <img src={paypalImage} alt="logo" />
                </div>
              </div>
              : navigate("/subscription")}
        </Container>
      </div>
    </>
  );
}
export default SubscriptionDetail;
