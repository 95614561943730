import { Container } from "react-bootstrap";
import styles from "../Content/Content.module.css";
import SublyApi from "../../helpers/Api";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { CONTENT } from "../../utils/Constants";
import { useTranslation } from "react-i18next";

//----------function for about us content-----------
function AboutUs() {
  const { t } = useTranslation();
  const [contentList, setContentList] = useState("");

  //---------function for get about us content api calling----------
  useEffect(() => {
    async function privacyContent() {
      const details = await SublyApi.getContent(CONTENT.ABOUT_US);
      details && setContentList(details.data.page_content);
    }
    privacyContent();
  }, []);

  return (
    <>
      <div className="main">
        {contentList && (
          <div className={styles.contentText}>
            <Container>
              <h2>{t("About_Us")}</h2>
              <div
                className="textSection"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(contentList),
                }}
              ></div>
            </Container>
          </div>
        )}
      </div>
    </>
  );
}
export default AboutUs;
