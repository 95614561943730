import styles from "./About.module.css";
import { Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../assets/Styles/Common.css";
import { CM, FT, LBS, IN, KG } from "../../commonComponents/UserDummyData";
import { BiFemale, BiMale } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSignupData1 } from "../../store/slices/UserSlice";
import { useForm } from "react-hook-form";
import { heightFtInToCm, weightLbsToKg } from "../../utils/ConvertHeight";
import { useNavigate } from "react-router-dom";
import $ from "jquery";

function About({ setSwitchTo, previousValue, setPreviousValue }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [genderError, setGenderError] = useState("");

  // --------function for open component top of the page ----------
  useEffect(() => {
    $("document").ready(function () {
      $(window).scrollTop(0);
    });
  }, []);

  const [showWeightSelect, setShowWeightSelect] = useState("");
  const [showHeightSelect, setShowHeightSelect] = useState("");
  const [selectFt, setSelectFt] = useState("");
  const [selectIn, setSelectIn] = useState("");
  const [selectCm, setSelectCm] = useState("");
  const [selectLbs, setSelectLbs] = useState("");
  const [selectKg, setSelectKg] = useState("");
  const [heightUnit, setHeightUnit] = useState("");
  const [weightUnit, setWeightUnit] = useState("");
  const [genderValue, setGenderValue] = useState("");
  const { signupData1 } = useSelector((state) => state.user);

  // function for conver height into cm
  const handleConvert = () => {
    let cm = selectCm;
    if (!genderValue) {
      setGenderError(t("ABOUT_ERROR_MESSAGE"));
    } else {
      if (showHeightSelect == "ft") {
        cm = heightFtInToCm(selectFt, selectIn);
        setSelectCm(cm);
      }
      let kg = selectKg;
      if (showWeightSelect == "lbs") {
        kg = weightLbsToKg(selectLbs);
        setSelectKg(kg);
      }

      dispatch(
        setSignupData1({
          gender: genderValue,
          height: cm,
          weight: kg,
          selectLbs: selectLbs,
          selectKg: selectKg,
          selectFt: selectFt,
          selectIn: selectIn,
          selectCm: selectCm,
          heightUnit: heightUnit,
          weightUnit: weightUnit,
        })
      );
      setSwitchTo(2);
    }
  };

  function handleError() {
    setGenderError("");
  }

  useEffect(() => {
    if (previousValue == true) {
      setGenderValue(signupData1.gender)
      setHeightUnit(signupData1.heightUnit)
      setWeightUnit(signupData1.weightUnit)
      setShowWeightSelect(signupData1.weightUnit)
      if (signupData1.weightUnit === "lbs") {
        setSelectLbs(signupData1.selectLbs);
      } else if (signupData1.weightUnit === "kg") {
        setSelectKg(signupData1.selectKg);
      }

      setShowHeightSelect(signupData1.heightUnit)
      if (signupData1.heightUnit === "ft") {
        setSelectFt(signupData1.selectFt);
        setSelectIn(signupData1.selectIn);
      }

      if (signupData1.heightUnit === "cm") {
        setSelectCm(signupData1.selectCm);
      }
    }
  }, [dispatch]);

  // function for manage radio buttons
  const handleRadioClick = (event) => {
    setHeightUnit(event.target.value);
    setShowHeightSelect(event.target.value);
    if (event.target.value === "ft") {
      setSelectFt("");
    } else if (event.target.value === "in") {
      setSelectIn("");
    }

    if (event.target.value === "cm") {
      setSelectCm("cm");
    }

    if (event.target.value === "lbs") {
      setSelectLbs("");
    } else if (event.target.value === "kg") {
      setSelectKg("");
    }
  };

  const handleSelectFt = (event) => {
    setSelectFt(event.target.value);
  };

  const handleSelectIn = (event) => {
    setSelectIn(event.target.value);
  };

  const handleSelectCm = (event) => {
    setSelectCm(event.target.value);
  };

  const handleSelectLbs = (event) => {
    setSelectLbs(event.target.value);
  };
  const handleSelectKg = (event) => {
    setSelectKg(event.target.value);
  };

  return (
    <div className="aboutMain">
      <div className={styles.aboutWrapper}>
        <Container>
          <Form>
            <div className={styles.formBlock}>
              <div className={styles.formSection}>
                <div className={styles.topHeading}>
                  <h3>{t("GENDER_HEADING")}</h3>
                  <span>*</span>
                </div>
                <div className={styles.isForm}>
                  <div className="gender middle aboutMiddle">
                    <label>
                      <input
                        type="radio"
                        name="gender"
                        value="female"
                        checked={genderValue === "female" ? true : false}
                        onChange={(e) => {
                          setGenderValue(e.target.value);
                          handleError();
                        }}
                      />
                      <div className="rad2 box">
                        <span>
                          <BiFemale />
                          {t("FEMALE")}
                        </span>
                      </div>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="gender"
                        value="male"
                        checked={genderValue === "male" ? true : false}
                        onChange={(e) => {
                          setGenderValue(e.target.value);
                          handleError();
                        }}
                      />
                      <div className="rad1 box">
                        <span>
                          <BiMale />
                          {t("MALE")}
                        </span>
                      </div>
                    </label>
                    {genderError && (
                      <div className="errorMsg">{genderError}</div>
                    )}
                  </div>
                  <div className={styles.inputBlock}>
                    <div className={styles.labelTitle}>
                      <p>{t("OPTIONAL_TEXT")}</p>
                      <h3>{t("HEIGHT")}</h3>
                    </div>
                    <div className="unit middle dFlex aboutMiddle">
                      <label>
                        <input
                          type="radio"
                          name="height_unit"
                          value="ft"
                          checked={heightUnit === "ft" ? true : false}
                          onClick={handleRadioClick}
                        />
                        <div className="rad2 box">
                          <span>{t("FT")}</span>
                        </div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="height_unit"
                          value="cm"
                          checked={heightUnit === "cm" ? true : false}
                          onClick={handleRadioClick}
                        />
                        <div className="rad1 box">
                          <span>{t("CM")}</span>
                        </div>
                      </label>
                    </div>

                    {showHeightSelect == "ft" ? (
                      <div className="aboutSelectCm">
                        <Form.Select
                          value={selectFt}
                          onChange={handleSelectFt}
                          className="FormInput"
                          aria-label="Default select example"
                        >
                          <option key={0} value="">
                            {t("FT")}
                          </option>
                          {FT.map((ft, ind) => (
                            <option key={ind + 1} value={ft.value}>
                              {ft.label} <span> {t("FT")}</span>
                            </option>
                          ))}
                        </Form.Select>

                        <Form.Select
                          value={selectIn}
                          onChange={handleSelectIn}
                          className="FormInput"
                          aria-label="Default select example"
                        >
                          <option key={0} value="">
                            {t("IN")}
                          </option>
                          {IN.map((In, ind) => (
                            <option key={ind + 1} value={In.value}>
                              {In.label} <span>{t("IN")}</span>
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    ) : (
                      showHeightSelect == "cm" && (
                        <div className="aboutSelectCm">
                          <Form.Select
                            value={selectCm}
                            onChange={handleSelectCm}
                            className="FormInput m-10"
                            aria-label="Default select example"
                          >
                            <option key={0} value="">
                              {t("CM")}
                            </option>
                            {CM.map((cm, ind) => (
                              <option key={ind + 1} value={cm.value}>
                                {cm.label} <span> {t("CM")}</span>
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      )
                    )}
                  </div>

                  <div className={styles.inputBlock}>
                    <div className={styles.labelTitle}>
                      <p>{t("OPTIONAL_TEXT")}</p>
                      <h3>{t("WEIGHT")}</h3>
                    </div>
                    <div className="unit middle dFlex aboutMiddle">
                      <label>
                        <input
                          type="radio"
                          name="weight_lbs"
                          value="lbs"
                          checked={weightUnit === "lbs" ? true : false}
                          onClick={(e) => {
                            setShowWeightSelect(e.target.value);
                            setWeightUnit(e.target.value);
                          }}
                        />
                        <div className="rad2 box">
                          <span>{t("LBS")}</span>
                        </div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="weight_lbs"
                          value="kg"
                          checked={weightUnit === "kg" ? true : false}
                          onClick={(e) => {
                            setShowWeightSelect(e.target.value);
                            setWeightUnit(e.target.value);
                          }}
                        />
                        <div className="rad1 box">
                          <span>{t("KG")}</span>
                        </div>
                      </label>
                    </div>
                    <div className="selectBox">
                      {showWeightSelect == "lbs" ? (
                        <div className="WeightField">
                          <Form.Select
                            value={selectLbs}
                            onChange={handleSelectLbs}
                            className="FormInput"
                            aria-label="Default select example"
                          >
                            <option key={0} value="">
                              {t("LBS")}
                            </option>
                            {LBS.map((lbs, ind) => (
                              <option key={ind + 1} value={lbs.value}>
                                {lbs.label} <span> {t("LBS")}</span>
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      ) : (
                        ""
                      )}
                      {showWeightSelect == "kg" ? (
                        <div className="WeightField">
                          <Form.Select
                            value={selectKg}
                            onChange={handleSelectKg}
                            className="FormInput"
                            aria-label="Default select example"
                          >
                            <option key={0} value="">
                              {t("KG")}
                            </option>
                            {KG.map((kg, ind) => (
                              <option key={ind + 1} value={kg.value}>
                                {kg.label} <span>{t("KG")}</span>
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className={styles.signUpBtn}>
                        <button
                         type="button"
                          onClick={() => {
                            setSwitchTo(0);
                            setPreviousValue(true);
                          }}
                        >
                          {t("PREVIOUS")}
                        </button>
                        <button 
                          type="button"
                          onClick={() => {
                            handleConvert();
                          }}
                        >
                          {t("NEXT_BUTTON")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Container>
      </div>
    </div>
  );
}

export default About;
